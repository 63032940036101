import React, { Component } from 'react';
import Slider from 'react-slick';

import { ChangeVariantTexture } from '../render';

import Button from 'components/Button';

import './ProductMenu.styles.scss';

const ProductVariant = ({ index, url, current, onClick }) => {
    const handleClick = () => {
        onClick(index);
    }

    return (
        <div className={'variant' + (current ? ' current' : '')} onClick={handleClick}>
            <img src={url} alt={url} onClick={handleClick} />
        </div>
    )
}

const Product = ({ name, price, thumbnail, variants, product, current, currentVariant, onClick, onVariantClick }) => {
    const handleClick = () => {
        onClick(product);
    }

    const handleVariantClick = index => {
        if (!current) {
            onClick(product, index);
        } else {
            onVariantClick(index);
        }
    }

    return (
        <div className={'product-menu-item' + (current ? ' current' : '')}>
            <div className="item-click-overlay" onClick={handleClick} />

            <div className="item-image">
                <img src={thumbnail} alt={thumbnail} />
            </div>

            <div className="item-main">
                <div className="item-name">
                    { name }
                </div>

                <div className="item-price">
                    ${ price }
                </div>
            </div>

            <div className="item-variants">
                {
                    variants.map((variant, index) => <ProductVariant key={variant} url={variant} index={index} current={current && currentVariant === index} onClick={handleVariantClick} />)
                }
            </div>
        </div>
    )
}

export default class ProductMenu extends Component {
    state = {
        currentVariant: '',
        // triedProducts: []
    }

    handleProductClick = (product, variantIndex) => {
        this.selectProduct(product, variantIndex);

        if (this.slider) {
            this.slider.slickGoTo(this.products.indexOf(product));
        }
    }

    handleVariantClick = index => {
        this.setState({
            currentVariant: index
        });

        if (this.props.isAR) {
            ChangeVariantTexture(index);
        }

    }

    handleSwipe = () => {
        setTimeout(() => {
            this.selectProduct(this.products[this.slider.innerSlider.state.currentSlide]);
        }, 10);
    }

    selectProduct(product, variantIndex = 0) {
        this.props.onProductSelected(product);
        this.currentProduct = null;
        this.setState({ currentVariant: variantIndex });

        if (this.props.isAR) {
            if (variantIndex !== 0) {
                setTimeout(() => {
                    ChangeVariantTexture(variantIndex);
                }, 300);
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.products[0] && !this.props.currentProduct) {
                this.selectProduct(this.products[0]);
            }
        }, 3000);
    }

    componentDidUpdate(props) {
        if (this.products[0] && (!this.props.currentProduct || !this.products.some(product => product.id === this.props.currentProduct.id))) {
            this.selectProduct(this.products[0]);
        }
    }

    get products() {
        if (this.props.productCategory) {
            return this.props.products.filter(p => p.category === this.props.productCategory);
        }

        return this.props.products;
    }

    setSlider = slider => this.slider = slider;

    render() {
        const products = this.products;

        const productsElement = products.map(product => (
            <Product
                key={product.id}
                name={product.name}
                price={45}
                thumbnail={product.thumbnail}
                variants={product.previewImageUrls}
                current={this.props.currentProduct?.id === product.id}
                currentVariant={this.state.currentVariant}
                product={product}
                onClick={this.handleProductClick}
                onVariantClick={this.handleVariantClick}
            />
        ));

        return (
            <div className="product-menu">
                <div className="product-menu-main mobile">
                    <Slider
                        arrows={false}
                        slidesToShow={1}
                        dots={false}
                        centerMode={true}
                        centerPadding="26%"
                        onSwipe={this.handleSwipe}
                        ref={this.setSlider}
                    >
                        { productsElement }
                    </Slider>

                </div>
                <div className="product-menu-main desktop">
                    { productsElement }
                </div>

                <div className="product-menu-foot">
                    <Button block={true}>BUY NOW</Button>
                </div>
            </div>
        )
    }
}
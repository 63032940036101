import React from 'react';

import './Spinner.styles.scss';

const Spinner = () => (
    <div className="spinner">
        <div className="cssload-thecube">
            <div className="cssload-cube cssload-c1"></div>
            <div className="cssload-cube cssload-c2"></div>
            <div className="cssload-cube cssload-c4"></div>
            <div className="cssload-cube cssload-c3"></div>
        </div>
    </div>
)

export default Spinner;

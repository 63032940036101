import * as THREE from "three";
import { OBJLoader } from "./OBJLoader";

const $ = window.$;
const TweenMax = window.TweenMax;

let currentObj;

let mirror = true;
let renderer;
let camera2D;
let camera3D;
let scene2D;
let scene3D;
let objects = [];
let objects_pivot_poses = [];

let videoTexture;
let videoSprite;

let faceMask;
let faceMaterial;

let loading = false; // this is to avoid load duplicate models
let currentProduct;
let realMask;
var cube;
let currentScale;

export const initThreejs = (fovy, video, videoCanvas) => {
  const videoElement = document.getElementById("facef-video");
  const canvas = document.getElementById("facef-canvas");
  const defaultWidth = videoElement.width;
  const defaultHeight = videoElement.height;

  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    preserveDrawingBuffer: true,
    antialias: true,
  });

  renderer.setClearColor("#e5e5e5");
  renderer.autoClear = false;

  // Create scene
  scene2D = new THREE.Scene();
  scene3D = new THREE.Scene();

  // Setup cameras
  camera2D = new THREE.OrthographicCamera(
    -defaultWidth / 2,
    defaultWidth / 2,
    defaultHeight / 2,
    -defaultHeight / 2,
    1,
    10
  );
  camera2D.position.z = 10;
  scene2D.add(camera2D);

  camera3D = new THREE.PerspectiveCamera(
    fovy,
    defaultWidth / defaultHeight,
    0.1,
    100
  );
  camera3D.position.set(0, 0, 0);
  scene3D.add(camera3D);

  let animate = () => {
    requestAnimationFrame(animate);
  };
  animate();

  cube = new THREE.Object3D();

  for (let index = 0; index < 730; index++) {
    // cube.add(new THREE.Mesh(new THREE.SphereGeometry(.1), new THREE.MeshBasicMaterial()));
    cube.add(new THREE.Object3D());
  }

  // Create lights
  const ambientLight = new THREE.AmbientLight(0x101030);
  scene3D.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffeedd);
  directionalLight.position.set(0, 0, 1);
  scene3D.add(directionalLight);

  // Create texture to apply on face
  faceMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });

  // Create video texture
  videoTexture = new THREE.Texture(videoCanvas);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;
  const videoMaterial = new THREE.MeshBasicMaterial({
    map: videoTexture,
    depthWrite: false,
    side: THREE.DoubleSide,
  });

  // Create video plane
  videoSprite = new THREE.Sprite(videoMaterial);
  videoSprite.center.set(0.5, 0.5);
  if (video.videoWidth > 0 && video.videoHeight > 0)
    videoSprite.scale.set(
      mirror ? -video.videoWidth : video.videoWidth,
      video.videoHeight,
      1
    );

  videoSprite.position.set(0, 0, 1);
  scene2D.add(videoSprite);
};

// TO ADD AN ADDITIONAL PRODCUCT
const addObj = (url, matArray, rtnObj) => {
  let piv_scale = 1;
  let pivot_pose = [0, 0, 0, 0, 0, 0];
  let object_root = null;
  let objLoader = new OBJLoader();

  objLoader.load(url, (object_root) => {
    object_root.children[0].material = matArray;
    scene3D.add(object_root);
    object_root.scale.x = piv_scale;
    object_root.scale.y = piv_scale;
    object_root.scale.z = piv_scale;
    objects.push(object_root);
    objects_pivot_poses.push(pivot_pose);
    rtnObj(object_root);
  });

  return object_root;
};

// CREATES REFERENCE FACE MESH
export const createFaceGeometry = (facefMagicFace) => {
  // Create face mesh
  const faceGeometry = new THREE.BufferGeometry();
  faceGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(facefMagicFace.faceModelObj.vertices, 3)
  );
  faceGeometry.setAttribute(
    "uv",
    new THREE.BufferAttribute(facefMagicFace.faceModelObj.uvs, 2)
  );
  faceGeometry.setIndex(
    new THREE.BufferAttribute(facefMagicFace.faceModelObj.faces, 1)
  );

  faceGeometry.computeBoundingSphere();
  faceGeometry.computeBoundingBox();
  faceGeometry.computeFaceNormals();
  faceGeometry.computeVertexNormals();

  faceMask = new THREE.Mesh(faceGeometry, faceMaterial);

  scene3D.remove(faceMask);

  let MaskMat = new THREE.MeshBasicMaterial();
  MaskMat.colorWrite = false;

  addObj("facef/mask.obj", MaskMat, (rtnObj) => {
    realMask = rtnObj;
  });
};

export const scaleProduct = (value) => {
  if (currentObj) {
    let scale = currentObj.scale.x;
    scale += value;
    currentScale = scale;
    currentObj.scale.set(scale, scale, scale);
  }
};

export const ChangeVariantTexture = (index) => {
  let tex = new THREE.TextureLoader();
  if (
    currentProduct.data.materials[0] !== undefined &&
    currentProduct.data.materials[0].maps !== undefined &&
    currentProduct.data.materials[0].maps.length > 0
  )
    tex.load(currentProduct.data.materials[0].maps[index], (texture) =>
      handleTextureChange(texture)
    );
  if (
    currentProduct.data.materials[1] !== undefined &&
    currentProduct.data.materials[1].maps !== undefined &&
    currentProduct.data.materials[1].maps.length > 0
  )
    tex.load(currentProduct.data.materials[1].maps[index], (texture) =>
      handleTextureChange(texture)
    );
};

const handleTextureChange = (texture) => {
  if (
    currentProduct.category === "Lipstick" ||
    currentProduct.category === "Eyebrows" ||
    currentProduct.category === "Blush"
  )
    faceMask.material.map = texture;
  else {
    if (
      currentObj.children[0] != null &&
      currentObj.children[0].material[0] != null &&
      currentObj.children[0].material[0].map != null
    )
      currentObj.children[0].material[0].map = texture;
    else if (
      currentObj.children[1] != null &&
      currentObj.children[1].material[0] != null &&
      currentObj.children[1].material[0].map
    )
      currentObj.children[1].material[0].map = texture;
    else if (
      currentObj.children[0] != null &&
      currentObj.children[0].material[1] != null &&
      currentObj.children[0].material[1].map != null
    )
      currentObj.children[0].material[1].map = texture;
    else if (
      currentObj.children[1] != null &&
      currentObj.children[1].material[1] != null &&
      currentObj.children[1].material[1].map
    )
      currentObj.children[1].material[1].map = texture;
  }
};

const FadeInMat = (mat) => {
  let temp = mat.opacity;
  mat.opacity = 0;
  TweenMax.to(mat, 0.5, { opacity: temp });
};

const FadeOutMat = (mat) => TweenMax.to(mat, 0.5, { opacity: 0 });

export const addProduct = (product) => {
  // if (currentObj) currentObj.scale.set(1, 1, 1);
  if (loading) return;
  loading = true;
  if (currentObj && currentObj.children > 0)
    currentObj.children[0].material.forEach((mat) => {
      FadeOutMat(mat);
    });
  currentProduct = product;
  $(".loadingMessage").fadeIn();
  let obj = currentObj;
  let matArray = [];
  let texLoader = new THREE.TextureLoader();

  product.data.materials.forEach((mat) => {
    let map = mat.maps[0] ? texLoader.load(mat.maps[0]) : null;
    let normalMap = mat.normalMap ? texLoader.load(mat.normalMap) : null;
    let envMap = mat.envMap ? texLoader.load(mat.envMap) : null;
    if (envMap) envMap.mapping = THREE.EquirectangularReflectionMapping;
    let m = new THREE.MeshStandardMaterial({
      map: map,
      normalMap: normalMap,
      opacity: mat.opacity,
      envMap: envMap,
      roughness: mat.roughness,
      metalness: mat.metalness,
      transparent: mat.transparent,
    });
    matArray.push(m);
  });

  if (product.category === "Eyewear" || product.category === "Headphones" || product.category === "Hats") {
    if (realMask) realMask.visible = true;
    scene3D.remove(faceMask);
    addObj(product.data.modelUrl, matArray, (rtnObj) => {
      $(".loadingMessage").fadeOut();
      if (obj) scene3D.remove(obj);
      currentObj = rtnObj;
      currentObj.children[0].material.forEach((mat) => {
        FadeInMat(mat);
      });

      currentScale && currentObj && currentObj.scale.set(currentScale, currentScale, currentScale);
      loading = false;
    });
  } else if (
    product.category === "Lipstick" ||
    product.category === "Eyebrows" ||
    product.category === "Blush"
  ) {
    if (obj) scene3D.remove(obj);
    scene3D.add(faceMask);
    if (realMask !== undefined) realMask.visible = false;
    if (faceMask !== undefined) {
      faceMask.material = matArray[0];
      FadeInMat(faceMask.material);
    }
    $(".loadingMessage").fadeOut();
    loading = false;
    currentScale && currentObj && currentObj.scale.set(currentScale, currentScale, currentScale);
  }
};

export const updateSize = (video, videoCanvas) => {
  // setTimeout for iOS to get correct video size
  setTimeout(() => {
    camera2D.left = -video.videoWidth / 2;
    camera2D.right = video.videoWidth / 2;
    camera2D.top = video.videoHeight / 2;
    camera2D.bottom = -video.videoHeight / 2;
    camera2D.updateProjectionMatrix();

    camera3D.aspect = video.videoWidth / video.videoHeight;
    camera3D.updateProjectionMatrix();

    videoCanvas.width = video.videoWidth;
    videoCanvas.height = video.videoHeight;

    if (video.videoWidth < 641)
      renderer.setSize(video.videoWidth * 2, video.videoHeight * 2);
    else renderer.setSize(video.videoWidth, video.videoHeight);

    videoSprite.scale.set(
      mirror ? -video.videoWidth : video.videoWidth,
      video.videoHeight,
      1
    );
  });
};

export const renderThreejs = (facefMagicFace) => {
  if (facefMagicFace.isFaceDetected) {
    let t = facefMagicFace.getObjectPosition();
    let R = facefMagicFace.getObjectRotation();
    const pos = new THREE.Vector3();
    const quat = new THREE.Quaternion();
    const scale = new THREE.Vector3();
    let l_transfMatrix = new THREE.Matrix4();
    l_transfMatrix.set(
      R[0][0],
      R[0][1],
      R[0][2],
      0,
      -R[1][0],
      -R[1][1],
      -R[1][2],
      0,
      -R[2][0],
      -R[2][1],
      -R[2][2],
      0,
      0.0,
      0.0,
      0.0,
      1.0
    );
    if (mirror) {
      l_transfMatrix.decompose(pos, quat, scale);
      // Mirror rotation transformation
      quat.y = -quat.y;
      quat.z = -quat.z;
      l_transfMatrix.compose(pos, quat, scale);
    }

    if (currentObj) currentObj.visible = true;

    // -- Set to objects
    objects.forEach((obj, i) => {
      if (obj) {
        obj.position.set(
          mirror ? -t[0] : t[0], // x mirror
          -t[1],
          -t[2]
        );

        obj.matrix = l_transfMatrix;
        obj.rotation.setFromRotationMatrix(l_transfMatrix);
        // -- pivot translation and rotation
        obj.translateX(objects_pivot_poses[i][0]);
        obj.translateY(objects_pivot_poses[i][1]);
        obj.translateZ(objects_pivot_poses[i][2]);
        obj.rotateX(THREE.Math.degToRad(objects_pivot_poses[i][3]));
        obj.rotateY(THREE.Math.degToRad(objects_pivot_poses[i][4]));
        obj.rotateZ(THREE.Math.degToRad(objects_pivot_poses[i][5]));
      }
    });
    faceMask.position.set(
      mirror ? -t[0] : t[0], // x mirror
      -t[1],
      -t[2]
    );

    faceMask.matrix = l_transfMatrix;
    faceMask.rotation.setFromRotationMatrix(l_transfMatrix);
    faceMask.visible = true;

    for (let i = 0; i < 730 * 3; ) {
      var index = i / 3;
      cube.children[index].position.x = -facefMagicFace.faceVertices[i++]; // x mirror
      cube.children[index].position.y = facefMagicFace.faceVertices[i++]; // y
      cube.children[index].position.z = facefMagicFace.faceVertices[i++];
    }

    cube.position.copy(faceMask.position);
    cube.quaternion.copy(faceMask.quaternion);

    //calculate distance between eyes
    // const v3 = cube.children[353].position.distanceTo(
    //   cube.children[269].position
    // );

    var eyes = true;
    if (eyes && currentObj) {
      // var size = v3 / 9;
      // currentObj.scale.set(size, size, size);
    }

    // Update vertices positions
    for (let i = 0; i < 730 * 3; ) {
      faceMask.geometry.attributes.position.array[i] = -facefMagicFace
        .faceVertices[i++]; // x mirror
      faceMask.geometry.attributes.position.array[i] =
        facefMagicFace.faceVertices[i++]; // y
      faceMask.geometry.attributes.position.array[i] =
        facefMagicFace.faceVertices[i++]; // z
    }
    faceMask.geometry.attributes.position.needsUpdate = true;
    faceMask.visible = true;
  } else {
    if (currentObj !== undefined) currentObj.visible = false;
    faceMask.visible = false;
  }
};

export const renderScene = () => {
  videoTexture.needsUpdate = true;
  renderer.clear();
  renderer.render(scene2D, camera2D);
  renderer.render(scene3D, camera3D);
};

import React from 'react';
import { Link } from 'react-router-dom';

import './ButtonCircle.styles.scss';

const Button = ({ children, to, size = '', onClick }) => {

    if (to) {
        return (
            <Link to={to} className={'btn-circle ' + size} onClick={onClick}>{ children }</Link>
        );
    }

    return (
        <button className={'btn-circle ' + size} onClick={onClick}>{ children }</button>
    );
}

export default Button;

import React from 'react';

import Icon from  './Icon';

const WIDTH = 1;
const HEIGHT = 41/68;

const Element = ({ className, getSize, color, style }) => (
    <svg className={className} style={style} width={getSize(WIDTH)} height={getSize(HEIGHT)} viewBox="0 0 68 41" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.773 26.845H5.18L4.238 31H.416l4.626-19.391h6.87L16.538 31h-3.823l-.942-4.155zm-5.817-3.49h5.014l-2.244-9.918h-.499l-2.271 9.917zM28.43 17.26h3.49V31h-3.49v-1.994h-.498c-.61 1.588-1.921 2.382-3.934 2.382a5.4 5.4 0 01-1.911-.333 4.142 4.142 0 01-1.552-1.025c-.443-.461-.794-1.034-1.052-1.717-.24-.702-.36-1.524-.36-2.465V17.26h3.49v7.867c0 .98.23 1.745.692 2.3.462.535 1.155.803 2.078.803 1.053 0 1.82-.333 2.3-.997.498-.684.747-1.607.747-2.77V17.26zM48.966 31h-3.657v-5.817c0-.444-.12-.804-.36-1.08-.222-.278-.6-.416-1.136-.416h-4.21V31h-3.657V11.609h7.424c.997 0 1.874.138 2.632.415.757.277 1.385.665 1.883 1.164.517.48.905 1.043 1.164 1.69.277.646.415 1.339.415 2.077v.665c0 .794-.212 1.542-.637 2.244-.425.683-1.08 1.21-1.967 1.579v.499c.647.073 1.154.323 1.524.747.388.425.582.96.582 1.607V31zm-9.364-10.804h3.49c.887 0 1.561-.203 2.023-.61.462-.424.693-1.015.693-1.772v-.332c0-.647-.231-1.2-.693-1.662-.462-.48-1.136-.72-2.022-.72h-3.49v5.096zm22.336 8.81c-.369.867-.886 1.486-1.55 1.855-.666.351-1.441.527-2.328.527a5.958 5.958 0 01-2.355-.471 5.577 5.577 0 01-1.91-1.385c-.555-.61-.998-1.348-1.33-2.216-.315-.868-.472-1.856-.472-2.964v-.444c0-1.09.157-2.068.471-2.936.314-.868.74-1.607 1.275-2.216a5.38 5.38 0 011.856-1.385 5.421 5.421 0 012.299-.499c.979 0 1.764.166 2.355.499.609.332 1.09.85 1.44 1.551h.499V17.26h3.49v9.585c0 .554.25.83.748.83h.526V31H64.57c-.628 0-1.145-.185-1.551-.554-.388-.37-.582-.85-.582-1.44h-.499zm-3.102-.942c1.016 0 1.828-.333 2.438-.998.609-.683.914-1.606.914-2.77v-.332c0-1.164-.305-2.078-.915-2.743-.609-.683-1.422-1.025-2.437-1.025-1.016 0-1.828.342-2.438 1.025-.61.665-.914 1.58-.914 2.743v.332c0 1.164.305 2.087.914 2.77.61.665 1.422.998 2.438.998z" fill={color}/>
    </svg>
)

const IconLogoText = ({ color, size, style, baseline, className }) => {
    return <Icon color={color} size={size} style={style} baseline={baseline} className={className}><Element /></Icon>;
}

export default IconLogoText;

import React from 'react';

import Icon from  './Icon';

const WIDTH = 1;
const HEIGHT = 18/22;

const Element = ({ className, getSize, color, style }) => (
    <svg className={className} style={style} width={getSize(WIDTH)} height={getSize(HEIGHT)} viewBox="0 0 22 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.222 1a1 1 0 00-.832.445L6.131 3.333H3.556A2.556 2.556 0 001 5.89v8.555A2.556 2.556 0 003.556 17h14a2.555 2.555 0 002.555-2.556V5.89a2.555 2.555 0 00-2.555-2.556H14.98l-1.26-1.888A1 1 0 0012.89 1H8.222z" stroke={color} fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.556 13.889a4.111 4.111 0 100-8.222 4.111 4.111 0 000 8.222z" stroke={color} fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

const IconCamera = ({ color, size, style, baseline, className }) => {
    return <Icon color={color} size={size} style={style} baseline={baseline} className={className}><Element /></Icon>;
}

export default IconCamera;

import { Product, ProductCategories, ProductData } from './Products'

let productCount = 0;

function id() {
    return productCount++
}

function MakeProduct(...vars) {
    return Product(id(), ...vars)
}

const ProductList = [
    MakeProduct(ProductCategories.EYEWEAR, 'Purse',
        'products/thumbs/Purse.png',
        [],
        ProductData([0, -5, 0], [0, 0, 0, 0], [.3, .3, .3], 'products/3d-preview/Purse_Single_Texture_111820.glb')
    ),
    MakeProduct(ProductCategories.EYEWEAR, 'Country Sofa',
        'products/thumbs/Country_Sofa.png',
        [],
        ProductData([0, -3, 0], [0, 0, 0, 0], [.22, .22, .22], 'products/3d-preview/Country_Sofa.glb')
    ),
    MakeProduct(ProductCategories.EYEWEAR, 'Fedora Hat',
        'products/thumbs/Fedora_Hat.png',
        [],
        ProductData([0, -2, 0], [0, 0, 0, 0], [1, 1, 1], 'products/3d-preview/Fedora_Hat_Single_Texture_111820.glb')
    ),
    MakeProduct(ProductCategories.EYEWEAR, 'Rhinestone Shoes',
        'products/thumbs/Rhinestone_Shoes.png',
        [],
        ProductData([0, -3, 0], [0, 0, 0, 0], [1, 1, 1], 'products/3d-preview/Rhinestone_Shoes_Set_011121.glb')
    ),
    MakeProduct(ProductCategories.EYEWEAR, 'Snakeskin Shoes',
        'products/thumbs/Snakeskin_Shoes.png',
        [],
        ProductData([0, -3, 0], [0, 0, 0, 0], [1, 1, 1], 'products/3d-preview/Snakeskin_Shoe_Single_Tex.glb')
    ),
]

export default ProductList

import React from 'react';

import Icon from  './Icon';

const WIDTH = 26/27;
const HEIGHT = 1;

const Element = ({ className, getSize, color, style }) => (
    <svg className={className} style={style} width={getSize(WIDTH)} height={getSize(HEIGHT)} viewBox="0 0 26 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.92 6.831L14.28 2.91a2.336 2.336 0 00-2.375 0L5.265 6.83a2.349 2.349 0 00-1.14 2.028v7.453a2.361 2.361 0 001.172 2.028l6.64 3.922a2.336 2.336 0 002.374 0l6.64-3.922a2.35 2.35 0 001.141-2.028V8.859a2.361 2.361 0 00-1.172-2.028zM19.748 8.86v6.55l-5.468-3.294V5.63l5.469 3.228zm-7.811-3.232v6.488L6.468 15.41V8.86l5.469-3.233zm1.171 14.607l-5.077-3.008 5.077-3.071 5.078 3.07-5.078 3.01zM25.873 22.588h-4v4.017h4v-4.017zM25.873.622h-4v4.017h4V.622zM4 .622H0v4.017h4V.622zM4 22.588H0v4.017h4v-4.017z" fill={color}/>
    </svg>
)

const IconLogo = ({ color, size, style, baseline, className }) => {
    return <Icon color={color} size={size} style={style} baseline={baseline} className={className}><Element /></Icon>;
}

export default IconLogo;

import { Product, ProductCategories, ProductData } from './Products'

let productCount = 0;

function id() {
    return productCount++
}

function MakeProduct(...vars) {
    return Product(id(), ...vars)
}

const ProductList = [

// EYEWEAR

// EYEWEAR
// MakeProduct(
//     ProductCategories.EYEWEAR,
//     "Wayfarer",
//     [
//       "products/eyewear/wayfarer/preview1.jpg",
//       "products/eyewear/wayfarer/preview2.jpg",
//       "products/eyewear/wayfarer/preview3.jpg"
//     ],
//     ProductData(
//       [0, 0, 0],
//       [0, 0, 0, 0],
//       [1, 1, 1],
//       "products/eyewear/wayfarer/model.obj",
//       [
//         {
//           maps: [
//             "products/eyewear/wayfarer/texture1.jpg",
//             "products/eyewear/wayfarer/texture2.jpg",
//             "products/eyewear/wayfarer/texture3.jpg"
//           ],
//           normalMap: null,
//           envMap: "products/envmap.jpg",
//           opacity: 0.8,
//           metalness: 0.3,
//           roughness: 0.2,
//           transparent: true
//         },
//         {
//           maps: [],
//           normalMap: null,
//           envMap: "products/envmap.jpg",
//           opacity: 0.3,
//           metalness: 1,
//           roughness: 0,
//           transparent: true
//         }
//       ]
//     )
//   ),
    MakeProduct(ProductCategories.EYEWEAR, 'Janie Glasses',
        'products/eyewear/janie/thumbnail.png',
        ['products/eyewear/janie/preview_tortoise.jpg',
         'products/eyewear/janie/preview_black.jpg',
         'products/eyewear/janie/preview_red.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/eyewear/janie/model.obj', [
            {
                maps: [ 'products/eyewear/janie/texture_tortoise.jpg',
                        'products/eyewear/janie/texture_black.jpg',
                        'products/eyewear/janie/texture_red.jpg'
            ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: .8,
                metalness: .3,
                roughness: .2,
                transparent: true
            },
            {
                maps: [],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: .3,
                metalness: 1,
                roughness: 0,
                transparent: true
            }
            
        ], "")
    ),

    MakeProduct(ProductCategories.EYEWEAR, 'Kinsey Glasses',
        'products/eyewear/kinsey/thumbnail.png',
        ['products/eyewear/kinsey/preview_gold.jpg',
         'products/eyewear/kinsey/preview_silver.jpg',
         'products/eyewear/kinsey/preview_rosegold.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/eyewear/kinsey/model.obj', [   
            {
                maps: [],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: .3,
                metalness: 1,
                roughness: 0,
                transparent: true

            }, 
            {
                maps: [ 'products/eyewear/kinsey/texture_gold.jpg',
                        'products/eyewear/kinsey/texture_silver.jpg',
                        'products/eyewear/kinsey/texture_rosegold.jpg'],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: 1,
                roughness: .05,
                transparent: true
            }
        ], "")
    ),

    MakeProduct(ProductCategories.EYEWEAR, 'Lovely Glasses',
        'products/eyewear/lovely/thumbnail.png',
        ['products/eyewear/lovely/preview_silver.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/eyewear/lovely/model.obj', [
            {
                maps: [],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: .3,
                metalness: 1,
                roughness: 0,
                transparent: true

            },
            {
                maps: [ 'products/eyewear/lovely/texture_silver.jpg'],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: 1,
                roughness: 0,
                transparent: true
            }
        ], "")
    ),

    // LIPSTICK

    MakeProduct(ProductCategories.LIPSTICK, 'Charm',
        'products/lipstick/charm/thumbnail.png',
        ['products/lipstick/charm/preview1.jpg',
        'products/lipstick/charm/preview2.jpg',
        'products/lipstick/charm/preview3.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/lipstick/charm/texture1.png',
                    'products/lipstick/charm/texture2.png',
                    'products/lipstick/charm/texture3.png'
                ],
                normalMap: null,
                opacity: .3,
                metalness: 0,
                roughness: 1,
                transparent: true


            }
        ], "")
    ),

    MakeProduct(ProductCategories.LIPSTICK, 'Glam',
        'products/lipstick/glam/thumbnail.png',
        ['products/lipstick/glam/preview1.jpg',
         'products/lipstick/glam/preview2.jpg',
         'products/lipstick/glam/preview3.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/lipstick/glam/model.obj', [
            {
                maps: [ 'products/lipstick/glam/texture1.png',
                        'products/lipstick/glam/texture2.png',
                        'products/lipstick/glam/texture3.png'],
                normalMap: null,
                envMap: null,
                opacity: .3,
                metalness: .1,
                roughness: 1,
                transparent: true


            }
        ], "")
    ),
    MakeProduct(ProductCategories.LIPSTICK, 'Pinup',
        'products/lipstick/pinup/thumbnail.png',
        ['products/lipstick/pinup/preview1.jpg',
         'products/lipstick/pinup/preview2.jpg',
         'products/lipstick/pinup/preview3.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/lipstick/pinup/model.obj', [
            {
                maps: [ 'products/lipstick/pinup/texture1.png',
                        'products/lipstick/pinup/texture2.png',
                        'products/lipstick/pinup/texture3.png'],
                normalMap: null,
                envMap: null,
                opacity: .3,
                metalness: .1,
                roughness: 1,
                transparent: true


            }
        ], "")
    ),
    MakeProduct(ProductCategories.LIPSTICK, 'Plum',
        'products/lipstick/plum/thumbnail.png',
        ['products/lipstick/plum/preview1.jpg',
         'products/lipstick/plum/preview2.jpg',
         'products/lipstick/plum/preview3.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/lipstick/plum/model.obj', [
            {
                maps: [ 'products/lipstick/plum/texture1.png',
                        'products/lipstick/plum/texture2.png',
                        'products/lipstick/plum/texture3.png'],
                normalMap: null,
                envMap: null,
                opacity: .3,
                metalness: .1,
                roughness: 1,
                transparent: true


            }
        ], "")
    ),


    // HEADPHONES

    MakeProduct(ProductCategories.HEADPHONES, 'Beats',
        'products/headphones/jenkins/thumbnail.png',
        ['products/headphones/jenkins/preview_white.jpg',
         'products/headphones/jenkins/preview_black.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/headphones/jenkins/model.obj', [
            {
                maps: [
                    'products/headphones/jenkins/texture_white.jpg',
                    'products/headphones/jenkins/texture_white.jpg'
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: .1,
                roughness: .0,
                transparent: true

            }, {
                maps: [
                    'products/headphones/jenkins/texture_black.jpg',
                    'products/headphones/jenkins/texture_black.jpg'
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: .0,
                roughness: .6,
                transparent: true

            }
        ], "")
    ),

    MakeProduct(ProductCategories.HEADPHONES, 'Maxwell',
        'products/headphones/maxwell/thumbnail.png',
        ['products/headphones/maxwell/preview_white.jpg',
         'products/headphones/maxwell/preview_black.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/headphones/maxwell/model.obj', [
            {
                maps: [
                    'products/headphones/maxwell/texture_white.jpg',
                    'products/headphones/maxwell/texture_black.jpg'
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: .4,
                roughness: .05,
                transparent: true
            }, {
                maps: [
                    'products/headphones/maxwell/earcaps.jpg',
                    'products/headphones/maxwell/earcaps.jpg'
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: 0,
                roughness: .5,
                transparent: true
            },
            {
                maps: [
                    'products/headphones/maxwell/texture_white.jpg',
                    'products/headphones/maxwell/texture_black.jpg'
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: 0,
                roughness: .4,
                transparent: true
            }
        ], "")
    ),

    MakeProduct(ProductCategories.HEADPHONES, 'Arena',
        'products/headphones/arena/thumbnail.png',
        ['products/headphones/arena/preview_black.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/headphones/arena/model.obj', [
            {
                maps: [
                    'products/headphones/arena/texture_black.jpg'
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: 0,
                roughness: .4,
                transparent: true

            } , {
                maps: [
                ],
                normalMap: null,
                envMap: 'products/envmap.jpg',
                opacity: 1,
                metalness: 1,
                roughness: .0,
                transparent: true

            }
        ], "")
    ),
        
    MakeProduct(ProductCategories.EYEBROWS, 'Kianna',
        'products/eyebrows/kianna/thumbnail.png',
        ['products/eyebrows/kianna/preview_black.jpg',
         'products/eyebrows/kianna/preview_brown.jpg',
         'products/eyebrows/kianna/preview_blonde.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/eyebrows/kianna/kianna_black.png',
                    'products/eyebrows/kianna/kianna_brown.png',
                    'products/eyebrows/kianna/kianna_blonde.png'
                ],
                normalMap: null,
                opacity: .8,
                metalness: .2,
                roughness: .5,
                transparent: true
            }
        ], "")
    ),

    MakeProduct(ProductCategories.EYEBROWS, 'Karmen',
        'products/eyebrows/karmen/thumbnail.png',
        ['products/eyebrows/karmen/preview_black.jpg',
         'products/eyebrows/karmen/preview_brown.jpg',
         'products/eyebrows/karmen/preview_blonde.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/eyebrows/karmen/karmen_black.png',
                    'products/eyebrows/karmen/karmen_brown.png',
                    'products/eyebrows/karmen/karmen_blonde.png'
                ],
                normalMap: null,
                opacity: .8,
                metalness: .2,
                roughness: .5,
                transparent: true
            }
        ], "")
    ),
    MakeProduct(ProductCategories.EYEBROWS, 'Billie',
        'products/eyebrows/billie/thumbnail.png',
        ['products/eyebrows/billie/preview_black.jpg',
         'products/eyebrows/billie/preview_brown.jpg',
         'products/eyebrows/billie/preview_blonde.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/eyebrows/billie/billie_black.png',
                    'products/eyebrows/billie/billie_brown.png',
                    'products/eyebrows/billie/billie_blonde.png'
                ],
                normalMap: null,
                opacity: .8,
                metalness: .2,
                roughness: .5,
                transparent: true
            }
        ], "")
    ),
    MakeProduct(ProductCategories.EYEBROWS, 'Zef',
        'products/eyebrows/zef/thumbnail.png',
        ['products/eyebrows/zef/preview_black.jpg',
         'products/eyebrows/zef/preview_brown.jpg',
         'products/eyebrows/zef/preview_blonde.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/eyebrows/zef/zef_black.png',
                    'products/eyebrows/zef/zef_brown.png',
                    'products/eyebrows/zef/zef_blonde.png'
                ],
                normalMap: null,
                opacity: .8,
                metalness: .2,
                roughness: .5,
                transparent: true
            }
        ], "")
    ),


    MakeProduct(ProductCategories.BLUSH, 'Bold',
        'products/blush/bold/thumbnail.png',
        ['products/blush/bold/preview_dark.jpg',
         'products/blush/bold/preview_rich.jpg',
         'products/blush/bold/preview_fair.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/blush/bold/bold_dark.png',
                    'products/blush/bold/bold_rich.png',
                    'products/blush/bold/bold_fair.png'
                ],
                normalMap: null,
                opacity: .8,
                metalness: 0,
                roughness: 1,
                transparent: true
            }
        ], "")
    ),

    MakeProduct(ProductCategories.BLUSH, 'Gentle',
        'products/blush/gentle/thumbnail.png',
        ['products/blush/gentle/preview_dark.jpg',
         'products/blush/gentle/preview_rich.jpg',
         'products/blush/gentle/preview_fair.jpg'],
        ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
            {
                maps: [
                    'products/blush/gentle/gentle_dark.png',
                    'products/blush/gentle/gentle_rich.png',
                    'products/blush/gentle/gentle_fair.png'
                ],
                normalMap: null,
                opacity: .8,
                metalness: 0,
                roughness: 1,
                transparent: true
            }
        ], "")
    ),

    // MakeProduct(ProductCategories.HATS, 'Fedora',
    //     'products/hats/fedora/thumbnail.png',
    //     ['products/hats/fedora/preview.jpg'],
    //     ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], '', [
    //         {
    //             maps: [
    //                 'products/hats/fedora/lambert5SG_baseColor.png',
    //             ],
    //             normalMap: null,
    //             opacity: .8,
    //             metalness: 0,
    //             roughness: 1,
    //             transparent: true
    //         }
    //     ], "")
    // ),

    // MakeProduct(ProductCategories.HATS, 'Fedora',
    //     'products/hats/fedora/thumbnail.png',
    //     ['products/hats/fedora/preview_black.jpg'],
    //     ProductData([0, 0, 0], [0, 0, 0, 0], [1, 1, 1], 'products/hats/fedora/model.obj', [
    //         {
    //             maps: [
    //                 'products/hats/fedora/Fedora_Hat_Hat_mat_Normal.jpg',
    //                 // 'products/hats/fedora/Fedora_Hat_Hat_mat_Roughness.jpg',
    //                 // 'products/hats/fedora/Fedora_Hat_Hat_mat_BaseColor.jpg'
    //             ],
    //             normalMap: null,
    //             envMap: 'products/envmap.jpg',
    //             opacity: .8,
    //             metalness: .3,
    //             roughness: .2,
    //             transparent: true

    //         }
    //         , {
    //             maps: [
    //                 'products/hats/fedora/Fedora_Strap1_mat_Normal.jpg',
    //         //         'products/hats/fedora/Fedora_Strap1_mat_Roughness.jpg',
    //         //         'products/hats/fedora/Fedora_Strap1_mat_BaseColor.jpg'
    //             ],
    //             normalMap: null,
    //             envMap: 'products/envmap.jpg',
    //             opacity: 1,
    //             metalness: 1,
    //             roughness: .0,
    //             transparent: true

    //         } , {
    //             maps: [
    //                 'products/hats/fedora/Fedora_Strap2_mat_Normal.jpg',
    //         //         'products/hats/fedora/Fedora_Strap2_mat_Roughness.jpg',
    //         //         'products/hats/fedora/Fedora_Strap2_mat_BaseColor.jpg'
    //             ],
    //             normalMap: null,
    //             envMap: 'products/envmap.jpg',
    //             opacity: 1,
    //             metalness: 1,
    //             roughness: .0,
    //             transparent: true

    //         }
    //     ], "")
    // ),
]

export default ProductList

import React, { Component } from "react";

import { scaleProduct } from '../render';

import ButtonCircle from 'components/ButtonCircle'

import IconPlus from 'icons/IconPlus'
import IconMinus from 'icons/IconMinus'

import './ScaleProduct.styles.scss';

export default class ScaleProduct extends Component {
    increment() {
        scaleProduct(0.05);
    }

    decrement() {
        scaleProduct(-0.05);
    }

    render() {
        return (
            <div className="scale-product">
                <ButtonCircle onClick={this.increment}>
                    <IconPlus />
                </ButtonCircle>

                <ButtonCircle onClick={this.decrement}>
                    <IconMinus />
                </ButtonCircle>
            </div>
        );
    }
}

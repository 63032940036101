import React from 'react'

import ButtonCircle from 'components/ButtonCircle';

import IconCamera from 'icons/IconCamera';

import './CameraButton.styles.scss';

const CameraButton = ({ onPhoto }) => {
    return (
        <div className="camera-button">
            <ButtonCircle size="large" onClick={onPhoto}>
                <IconCamera />
            </ButtonCircle>
        </div>
    )
}

export default CameraButton;

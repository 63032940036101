import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Main from 'components/Main';

import './App.styles.scss';

export default class App extends Component {
    render() {
        return (
            <Router>
                <div className="app">
                    <Main />
                </div>
            </Router>

        );
    }
}

import React from 'react';
import { Link } from 'react-router-dom';

import './Button.styles.scss';

const Button = ({ size = '', block, children, to, onClick }) => {
    if (to) {
        return (
            <Link to={to} className={'btn ' + size + (block ? ' btn-block' : '')} onClick={onClick}>{ children }</Link>
        );
    }

    return (
        <button className={'btn ' + size + (block ? ' btn-block' : '')} onClick={onClick}>{ children }</button>
    );
}

export default Button;

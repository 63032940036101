import React from 'react'
import Dropdown from 'react-dropdown'
import { Link } from 'react-router-dom';
import { ProductCategories } from '../Products'

import IconLogo from 'icons/IconLogo';
import IconLogoText from 'icons/IconLogoText';

import './Header.styles.scss';

const Header = ({ currentProductCategory, onCategorySelected }) => {
    return (
        <nav className="header">
            <Link to="/" className="logo">
                <h1>
                    <IconLogo size="26px" />

                    <IconLogoText size="68px" />
                </h1>
            </Link>

            {
                onCategorySelected ? (
                    <div className="header-right">
                        <Dropdown className="Dropdown" options={Object.values(ProductCategories)} onChange={onCategorySelected} value={currentProductCategory} placeholder={ProductCategories.EYEWEAR} />
                    </div>
                ) : null
            }
        </nav>
    )
}

export default Header

import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { useWindowResize } from 'beautiful-react-hooks'; 
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import Spinner from 'components/Spinner';

import './ObjectViewer.styles.scss';

const ObjectViewer = ({ model }) => {
    const container = useRef(null);
    const camera = useRef(null);
    const renderer = useRef(null);
    const gltf = useRef(null);
    const controls = useRef(null);
    const [loading, setLoading] = useState(true);

    const scene = useMemo(() => {
        const threeScene = new THREE.Scene();

        threeScene.background = new THREE.Color( 0xffffff );

        const ambientLight = new THREE.AmbientLight( 0x404040 );
        threeScene.add( ambientLight );

        return threeScene;
    }, []);

    const render = useCallback(() => {
        renderer.current.render( scene, camera.current );
    }, [renderer, scene, camera])

    useEffect(() => {
        const containerSize = container.current.getBoundingClientRect();
        camera.current = new THREE.PerspectiveCamera( 45, containerSize.width / containerSize.height, 1, 2000 );

        camera.current.position.z = 250;

        const pointLight = new THREE.PointLight( 0xffffff, 0.8 );
        camera.current.add( pointLight );

        const productLightTop = new THREE.PointLight( 0xffffff, .8 );
        productLightTop.position.set(0, 10, 0);
        scene.add( productLightTop )

        const productLightFront = new THREE.PointLight( 0xffffff, .8 );
        productLightFront.position.set(0, 0, 10);
        scene.add( productLightFront )

        const productLightBack = new THREE.PointLight( 0xffffff, .8 );
        productLightBack.position.set(0, 0, -10);
        scene.add( productLightBack )

        scene.add( camera.current );

        renderer.current = new THREE.WebGLRenderer( { antialias: true } );
        renderer.current.setPixelRatio( window.devicePixelRatio );
        renderer.current.setSize( containerSize.width, containerSize.height );
        renderer.current.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.current.toneMappingExposure = 1;
        renderer.current.outputEncoding = THREE.sRGBEncoding;

        container.current.appendChild( renderer.current.domElement );

        const pmremGenerator = new THREE.PMREMGenerator( renderer.current );
        pmremGenerator.compileEquirectangularShader();

        controls.current = new OrbitControls( camera.current, renderer.current.domElement );
        controls.current.addEventListener( 'change', render ); // use if there is no animation loop
        controls.current.minDistance = 15;
        controls.current.maxDistance = 28;
        controls.current.update();

        render();
    }, [container, render, scene]);

    useEffect(() => {
        setLoading(true);

        const loader = new GLTFLoader();
        loader.load(model.data.modelUrl, response => {
            if (gltf.current) {
                scene.remove(gltf.current.scene);
            }

            const displayScale = window.innerWidth > 768 ? 1.5 : 1;

            gltf.current = response;
            gltf.current.scene.traverse(child => {});
            gltf.current.scene.position.x = model.data.position[0];
            gltf.current.scene.position.y = model.data.position[1];
            gltf.current.scene.position.z = model.data.position[2];
            gltf.current.scene.scale.x = model.data.scale[0] * displayScale;
            gltf.current.scene.scale.y = model.data.scale[1] * displayScale;
            gltf.current.scene.scale.z = model.data.scale[2] * displayScale;
            controls.current.reset();
            scene.add( gltf.current.scene );
            render();

            setLoading(false);
        });
    }, [model, render, scene]);

    useWindowResize(event => {
        const containerSize = container.current.getBoundingClientRect();
        camera.current.aspect = containerSize.width / containerSize.height;
        camera.current.updateProjectionMatrix();

        renderer.current.setSize( containerSize.width, containerSize.height );

        render();
    });

    return (
        <div className={'object-viewer-holder' + (loading ? ' loading' : '')}>
            <Spinner />

            <div className="object-viewer" ref={container} />
        </div>
    )
}

export default ObjectViewer;

import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector'

import Header from 'components/Header';
import CameraPermissionOverlay from 'components/CameraPermissionOverlay'
import SharingModal from 'components/SharingModal'
import { ProductCategories } from 'components/Products'
import ARProductList from 'components/MockProductProvider';
import RotatorProductList from 'components/ObjectViewerProducts';
import ProductMenu from 'components/ProductMenu'
import { FaceFilter } from 'components/FaceFilter'
import { addProduct } from 'components/render';
import CameraButton from 'components/CameraButton';
import ObjectViewer from 'components/ObjectViewer';

import './ARApp.styles.scss';

const camImage = 'img/camera.png';

const ARApp = ({ isAR }) => {
    const [displayOverlay, setDisplayOverlay] = useState(false);
    const [displaySharingModal, setDisplaySharingModal] = useState(false);
    const [currentProductCategory, setCurrentProductCategory] = useState(ProductCategories.EYEWEAR);
    const [currentProduct, setCurrentProduct] = useState(0);
    const [snapUrl, setSnapUrl] = useState('');
    const [appLoading, setAppLoading] = useState(true);

    const handleTouchMove = event => {
        event.preventDefault();
    }

    useEffect(() => {
        setTimeout(() => {
            setAppLoading(false);
        }, 100);

        document.body.addEventListener('touchmove', handleTouchMove, {
            passive: false,
            useCapture: false
        });

        return () => {
            document.body.removeEventListener('touchmove', handleTouchMove, {
                passive: false,
                useCapture: false
            });
        }
    }, []);

    const handlePermissionOverlayClick = () => {
        setDisplayOverlay(false);
    }

    const handleCategorySelected = event => {
        setCurrentProductCategory(event.value);
    }

    const handleProductSelected = payload => {
        setCurrentProduct(payload);

        if (isAR) {
            addProduct(payload);
        }
    }

    const openSharingModal = (e) => {
        setDisplaySharingModal(true);
    }

    const closeSharingModal = (e) => {
        e.preventDefault()
        setDisplaySharingModal(false);
    }


    const takePhoto = (e) => {
        var canvas = document.getElementById('facef-canvas');
        var dataURL = canvas.toDataURL('image/jpg');

        //SAVE IMAGE TEMPORARY TO SERVER AND GET BACK URL TO SHARE IN SOCIAL PLATFORM

// var x = this;
//     storageRef.putString(dataURL, 'base64').then(function (snapshot) {
//       console.log('Uploaded a base64 string!');
//       x.setState({snapUrl:snapshot});
//     });

        setSnapUrl(dataURL)
        openSharingModal(e);
    }
    

    return (
        <div className="page page-app">
            {
                displayOverlay ? (
                    <CameraPermissionOverlay isLoading={appLoading} onButtonClick={handlePermissionOverlayClick} />
                ) : null
            }

            <Header currentProductCategory={isAR && currentProductCategory} onCategorySelected={isAR && handleCategorySelected} />

            <div className="app-inner">
                <div className="app-main">
                    <div className="app-pane">
                        {
                            isAR ? (
                                <React.Fragment>
                                    <FaceFilter />

                                    <CameraButton onPhoto={takePhoto} image={camImage} />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {
                                        currentProduct?.data ? (
                                            <ObjectViewer model={currentProduct} />
                                        ) : null
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>

                    <ProductMenu
                        productCategory={isAR && currentProductCategory}
                        products={isAR ? ARProductList : RotatorProductList}
                        currentProduct={currentProduct}
                        isAR={isAR}
                        onProductSelected={handleProductSelected}
                    />
                </div>
            </div>

            <ReactResizeDetector handleWidth handleHeight onResize={null} />

            <SharingModal showModal={displaySharingModal} SnapUrl={snapUrl} onClose={closeSharingModal} />
        </div>
    )
}

export default ARApp;

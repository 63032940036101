import React from 'react';

import Icon from  './Icon';

const WIDTH = 1;
const HEIGHT = 12/13;

const Element = ({ className, getSize, color, style }) => (
    <svg className={className} style={style} width={getSize(WIDTH)} height={getSize(HEIGHT)} viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.552 1.143v9.466M1.82 5.876h9.466" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

const IconPlus = ({ color, size, style, baseline, className }) => {
    return <Icon color={color} size={size} style={style} baseline={baseline} className={className}><Element /></Icon>;
}

export default IconPlus;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from 'pages/Home';
import ARApp from 'pages/ARApp';

const Main = () => {
    return (
        <Switch>
            <Route path="/try-on">
                <ARApp isAR={true} />
            </Route>

            <Route path="/rotator">
                <ARApp />
            </Route>

            <Route path="/">
                <Home />
            </Route>
        </Switch>
    )
}

export default Main;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const BASE_CLASSNAME = 'icon';

const Icon = ({ size, color, className, baseline, style, children }) => {
    const getSize = useCallback(factor => {
        return size.replace(/(\d+)/, match => match * factor);
    }, [size]);

    return React.Children.map(children, child => React.cloneElement(child, {
        className: `${BASE_CLASSNAME}${(baseline ? ' align-baseline' : '')} ${className}`,
        getSize,
        color,
        style
    }));
}

Icon.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    baseline: PropTypes.bool
}

Icon.defaultProps = {
    color: 'currentColor',
    size: '1em',
    className: ''
}

export default Icon;

import React from 'react';

import Button from 'components/Button';

import './Home.styles.scss';

const Home = () => {
    return (
        <div className="page page-home">
            <div className="home-background">
                <div className="hex"><div className="hex-inner" /></div>
                <div className="hex"><div className="hex-inner" /></div>
                <div className="hex"><div className="hex-inner" /></div>
                <div className="hex"><div className="hex-inner" /></div>
                <div className="hex"><div className="hex-inner" /></div>
                <div className="hex"><div className="hex-inner" /></div>
                <div className="hex"><div className="hex-inner" /></div>
            </div>

            <div className="home-content">
                <div className="logo" />

                <p>Advanced virtual product try on technology for every retail business.</p>

                <Button to="/try-on">Virtual Try-on</Button>

                <Button to="/rotator">3D Rotator</Button>
            </div>
        </div>
    )
}

export default Home;
